<template>
  <b-sidebar
    id="add-new-product-sidebar"
    :visible="isAddNewProductSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-product-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.products.createNewProduct") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewproduct)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                id="name"
                v-model="productData.name"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider name="descrição" rules="required">
            <b-form-group label-for="description">
              <template v-slot:label>
                {{ $t("message.projects.description") }}
              </template>
              <quill-editor
                id="title"
                style="margin-bottom: 82px; height: 300px"
                :options="editorOption"
                v-model="productData.description"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="valor"
            rules="required"
          >
            <b-form-group
              label-for="value"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.expenses.value") }}
              </template>
              <b-form-input
                id="value"
                v-money="getCurrencyFormat()"
                v-model="productData.initialValue"
                format="currency"
              />
            </b-form-group>
          </validation-provider>

          <!-- Projects -->
          <validation-provider #default="validationContext" name="projeto">
            <b-form-group
              label-for="projectId"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.projects") }}
              </template>
              <v-select
                multiple
                v-model="productData.projects"
                :options="projectOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="product-project"
              />
            </b-form-group>
          </validation-provider>

          <!-- Contracts -->
          <validation-provider #default="validationContext" name="projeto">
            <b-form-group
              label-for="projectId"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.contracts.contracts") }}
              </template>
              <v-select
                multiple
                v-model="productData.contracts"
                :options="contractOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="product-project"
              />
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import date from "@/@core/utils/date";
import currency from "@/@core/utils/currency";
import { VMoney } from "v-money";
import Vue from "vue";
import VueNumberInput from "@chenfengyuan/vue-number-input";
Vue.use(VueNumberInput);
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  model: {
    prop: "isAddNewProductSidebarActive",
    event: "update:is-add-new-product-sidebar-active",
  },
  props: {
    isAddNewProductSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectOptions: {
      type: Array,
      required: true,
    },
    contractOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,

      //Param
      paramData: store.state.user.paramData,
      //CurrentUser
      userData: store.state.user.userData,
      disabledButton: false,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    async submitNewproduct() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          this.productData.status = true;

          if (this.$i18n.locale === "en") {
            this.productData.initialValue = Number(
              this.productData.initialValue.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          } else {
            this.productData.initialValue = this.switchDotsAndCommas(
              this.productData.initialValue
            );
            this.productData.initialValue = Number(
              this.productData.initialValue.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          }

          axios({
            method: "post",
            url: `${URL_API}product`,
            headers: getHeader(this.userData),
            data: this.productData,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: this.$i18n.t("message.toast.create"),
                },
              });
            })
            .then(() => {
              this.$root.$emit("newListProduct", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },
  },

  setup(props, { emit }) {
    const blankproductData = {};

    const productData = ref(JSON.parse(JSON.stringify(blankproductData)));
    const resetproductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankproductData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-product-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetproductData);

    return {
      productData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-product-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
