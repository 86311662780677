<template>
  <div>
    <product-add-new
      :is-add-new-product-sidebar-active.sync="isAddNewProductSidebarActive"
      :project-options="projectOptions"
      :contract-options="contractOptions"
    />
    <b-row>
      <!-- Search -->
      <b-col cols="12" md="12">
        <div
          v-bind:class="{
            'align-items-center justify-content-end': isActiveCards,
            'd-flex align-items-center justify-content-end': !isActiveCards,
          }"
        >
          <b-input-group class="mr-1">
            <b-form-input
              v-model="searchQuery"
              @keyup.enter="searchWord()"
              :placeholder="$t('message.bookmark.search')"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="searchWord()"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <br v-if="isActive" />
          <div
            v-bind:class="{
              'd-flex align-items-center': !isActiveCards,
              'd-flex align-items-center justify-content-end': isActiveCards,
            }"
          >
            <b-form-radio-group
              v-model="itemView"
              class="ml-1 mr-1 list item-view-radio-group"
              buttons
              button-variant="outline-primary"
            >
              <b-form-radio
                v-for="option in itemViewOptions"
                :key="option.value"
                :value="option.value"
              >
                <feather-icon :icon="option.icon" />
              </b-form-radio>
            </b-form-radio-group>

            <b-button
              variant="primary"
              @click="isAddNewProductSidebarActive = true"
              v-if="restrictions('button_new_product')"
            >
              <span class="text-nowrap">{{
                $t("message.buttons.newProduct")
              }}</span>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <section v-if="productList.length === 0 && !isBusy">
      <div
        class="text-center"
        style="color: #c0c0c0; margin-top: 17.2rem; margin-bottom: 17.2rem"
      >
        <strong> {{ $t("message.registerNotFound") }}</strong>
      </div>
    </section>

    <section v-if="isBusy">
      <div
        class="text-center"
        style="color: #c0c0c0; margin-top: 17.2rem; margin-bottom: 17.2rem"
      >
        <b-spinner class="align-middle"></b-spinner>
        <strong> {{ $t("message.load") }}... </strong>
      </div>
    </section>

    <!-- Products -->
    <section v-else class="wishlist-items" :class="itemView">
      <b-card
        v-for="product in productList"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <!-- Product Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'apps-product-view', params: { id: product.id } }"
            >
              <h3 :id="'product-name-' + product.id">{{ product.name }}</h3>
              <b-tooltip
                v-if="product.name.length > 22"
                custom-class="long-tooltip"
                :target="'product-name-' + product.id"
                triggers="hover"
                no-fade
              >
                {{ product.name }}
              </b-tooltip>
            </b-link>
          </h6>
          <b-card-text class="item-description">
            <span v-html="product.description.substring(0, 50) + '..'"></span>
          </b-card-text>

          <b-badge :variant="badgeColor(product.status)">
            {{ getStatus(product.status) }}
          </b-badge>

          <br />
          <br />
        </b-card-body>
      </b-card>
    </section>

    <!-- Pagination -->
    <section v-if="showPagination">
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center mt-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRegisters"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
            @input="eventPage(currentPage)"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BDropdown,
  BSpinner,
  BDropdownItem,
  BBadge,
  BProgress,
  BProgressBar,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
//import useproductsList from "../product-list/useProductsList";
import { useShopUi } from "./useProductCards";
import ProductAddNew from "./ProductAddNew.vue";
import { ref } from "@vue/composition-api";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import CharacterLimit from "@core/utils/characterLimit";
import store from "@/store";

export default {
  directives: {
    Ripple,
  },
  components: {
    ProductAddNew,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BBadge,
    BProgress,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BSpinner,
    BProgressBar,
    BTooltip,
  },

  data() {
    return {
      productList: [],
      searchQuery: "",
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      size: 16,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,
      productListAll: [],

      //Select
      projectOptions: [],
      contractOptions: [],

      //Param
      paramData: store.state.user.paramData,

      //CurrentUser
      userData: store.state.user.userData,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,
      isActiveCards: true,
      limitCharactersValue: 20,
    };
  },

  async created() {
    this.getProductList();
    this.onResize();

    var projectList = [];
    var projectId = [];
    await axios
      .get(`${URL_API}project/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          projectList.push(element);
          projectId.push(id);
          this.projectOptions.push({
            label: projectList[i],
            value: projectId[i],
          });
        }
      });

    var contractList = [];
    var contractId = [];
    await axios
      .get(`${URL_API}contract/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status) {
            const element = response.data[i].name;
            const id = response.data[i].id;
            contractList.push(element);
            contractId.push(id);
            this.contractOptions.push({
              label: contractList[i],
              value: contractId[i],
            });
          }
        }
      });
  },

  mounted() {
    this.$root.$on("newListProduct", (newListProduct) => {
      this.isBusy = true;

      axios
        .get(
          `${URL_API}product/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.productList = response.data.content;
          this.totalRegisters = response.data.totalElements;
          this.perPage = response.data.numberOfElements;
          this.currentPage = 1;
          this.isBusy = false;
        });
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  setup() {
    const isAddNewProductSidebarActive = ref(false);

    const { itemView, itemViewOptions } = useShopUi();

    return {
      itemView,
      itemViewOptions,
      isAddNewProductSidebarActive,
    };
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },

    limitCharacters(value) {
      return CharacterLimit(value, this.limitCharactersValue);
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 1680) {
        this.isActive = false;
        this.limitCharactersValue = 20;
      } else {
        this.isActive = true;
        this.limitCharactersValue = 15;
      }

      this.isActiveCards = ListWidth(this.windowWidth);
    },

    getColLabel(colName) {
      return this.$i18n.t(colName);
    },

    async getAllProductList() {
      this.isBusy = true;
      this.showPagination = false;

      await axios
        .get(
          `${URL_API}product/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.productListAll = response.data.content;
        });
    },

    async getProductList() {
      this.isBusy = true;
      this.showPagination = false;
      await axios
        .get(
          `${URL_API}product/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.totalRegisters = response.data.totalElements;
          this.productList = response.data.content;
          this.perPage = response.data.numberOfElements;
          this.isBusy = false;
        });
      this.isBusy = false;

      if (this.productList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
        this.currentPage = 1;
      }
    },

    async eventPage(currentPage) {
      this.isBusy = true;

      await axios
        .get(
          `${URL_API}product/list-dto?page=${currentPage - 1}&sort=id,${
            this.paramData.listSort
          }&size=${this.paramData.cardSize}`,
          { headers: getHeader(this.userData) }
        )
        .then((response) => {
          this.productList = response.data.content;
          this.isBusy = false;
        });

      if (this.productList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }
    },

    async getProductFilter() {
      this.isBusy = true;
      this.showPagination = false;
      await axios
        .get(`${URL_API}product/search?searchTerm=${this.searchQuery}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.productList = [];
          if (response.data != []) {
            for (var i = 0; i < response.data.length; i++) {
              this.productList.push(response.data[i]);
            }
          }
          this.isBusy = false;
          this.showPagination = false;
        });
    },

    searchWord() {
      if (this.searchQuery) {
        this.getProductFilter();
      } else {
        this.getProductList();
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    getColLabel(colName) {
      return this.$i18n.t("message.products." + colName);
    },

    badgeColor(value) {
      if (value === true) {
        return "success";
      }
      return "danger";
    },

    getStatus(value) {
      if (value === true) {
        return this.getColLabel("active");
      }
      return this.getColLabel("notActive");
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.long-tooltip::v-deep .tooltip-inner {
  max-width: 45rem;
}
</style>
